"use client"

import { Language, SUPPORTED_LANGUAGES } from "@cyna/common/constants"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTriggerPrimitive,
} from "@cyna/components/ui/select"
import { LayoutHeader } from "@cyna/web/components/LayoutHeader"
import { useI18n } from "@cyna/web/hooks/useI18n"
import { useRouter } from "@cyna/web/hooks/useRouter"
import { LanguageIcon } from "@heroicons/react/24/outline"
import { ReactNode, useCallback } from "react"

const RootLayout = ({
  children,
}: Readonly<{
  children: ReactNode
}>) => {
  const [t, i18n] = useI18n()
  const router = useRouter()
  const changeLanguage = useCallback(
    (language: string) => {
      router.replace(window.location.pathname.slice(3), language as Language)
    },
    [router],
  )

  return (
    <>
      <LayoutHeader>
        <Select value={i18n.language} onValueChange={changeLanguage}>
          <SelectTriggerPrimitive asChild>
            <button className="ml-auto flex w-fit gap-2 text-2xl">
              <LanguageIcon className="h-6 w-6" />
            </button>
          </SelectTriggerPrimitive>
          <SelectContent>
            {SUPPORTED_LANGUAGES.map((lang) => (
              <SelectItem key={lang} value={lang}>
                {t(`common:languages.${lang}`)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </LayoutHeader>
      <main className="mx-auto flex h-full min-w-96 max-w-xl grow flex-col gap-4 p-4 xl:px-0">
        {children}
      </main>
    </>
  )
}

export default RootLayout
