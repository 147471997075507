import { Language } from "@cyna/common/constants"
import { useRouter as useNextRouter, useParams } from "next/navigation"
import { useMemo } from "react"

export const useRouter = (forceLang?: Language) => {
  const { lang } = useParams<{ lang: Language }>()
  const router = useNextRouter()
  const language = forceLang ?? lang
  const customRouter = useMemo(
    () => ({
      replace: (url: string, forceLanguage: Language = language) => {
        router.replace(`/${forceLanguage}${url}`)
      },
      push: (url: string, forceLanguage: Language = language) => {
        router.push(`/${forceLanguage}${url}`)
      },
    }),
    [language, router],
  )

  return customRouter
}
