import { Language } from "@cyna/common/constants"
import { cn } from "@cyna/components/lib/utils"
import NextLink from "next/link"
import { useParams } from "next/navigation"
import { forwardRef } from "react"

export type LinkProps = Parameters<typeof NextLink>[0] & {
  query?: Record<string, string>
}

export const Link = forwardRef<
  HTMLAnchorElement,
  LinkProps & { styless?: boolean }
>(({ className, styless, href, ...otherProps }, ref) => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const cls = cn(styless || "underline", className)
  const { lang } = useParams<{ lang: Language }>()
  const url = typeof href === "string" ? href : (href.href ?? "")
  const Component = url.startsWith("/") ? NextLink : "a"

  return (
    <Component
      ref={ref}
      className={cls}
      href={Component === "a" ? url : `/${lang}${url}`}
      {...otherProps}
    />
  )
})
