import { LoaderIcon } from "@cyna/components/base/Loader"
import { Typo } from "@cyna/components/base/Typo"
import { cn } from "@cyna/components/lib/utils"
import { useIsFetching, useIsMutating } from "@tanstack/react-query"
import { HTMLAttributes, ReactNode } from "react"

export const PageTitle = ({
  children,
  cta,
  className,
  ...otherProps
}: HTMLAttributes<HTMLHeadingElement> & {
  cta?: ReactNode
}) => {
  const isFetching = Boolean(useIsFetching())
  const isMutating = Boolean(useIsMutating())

  return (
    <div
      className={cn(
        "bg-background mx-auto flex w-full max-w-4xl items-center justify-between gap-4 px-4 py-4 lg:px-0",
        className,
      )}
    >
      <div className="flex items-center gap-4">
        <Typo as="h1" {...otherProps}>
          <h1>{children}</h1>
        </Typo>
        {(isFetching || isMutating) && <LoaderIcon />}
      </div>
      {cta}
    </div>
  )
}

export const PageSubTitle = ({
  children,
  ...otherProps
}: HTMLAttributes<HTMLHeadingElement>) => (
  <Typo as="h2" className="bg-background w-full" {...otherProps}>
    <h2>
      <div className="mx-auto flex max-w-4xl items-center gap-4">
        {children}
      </div>
    </h2>
  </Typo>
)
