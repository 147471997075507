import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export const runOnce = (
  fn: (...args: unknown[]) => void | Promise<void>,
  timeout?: number,
): NodeJS.Timeout =>
  setTimeout(() => {
    void fn()
  }, timeout ?? 0)
