"use client"

import { Language } from "@cyna/common/constants"
import { useParams } from "next/navigation"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const isSSR = typeof window === "undefined"

export const useI18n = (...ns: string[]) => {
  const { lang } = useParams<{ lang: Language }>()
  const ret = useTranslation(ns)
  const { i18n } = ret
  const [activeLang, setActiveLang] = useState(i18n.resolvedLanguage)

  useEffect(() => {
    if (activeLang === i18n.resolvedLanguage) {
      return
    }

    setActiveLang(i18n.resolvedLanguage)
  }, [activeLang, i18n.resolvedLanguage])
  useEffect(() => {
    if (lang !== i18n.language) {
      void i18n.changeLanguage(lang)
    }
  }, [lang, i18n])

  if (isSSR && i18n.resolvedLanguage !== lang) {
    void i18n.changeLanguage(lang)

    return ret
  }

  return ret
}
