import { routes } from "@cyna/common/routes"
import { Link } from "@cyna/components/base/Link"
import { Logo } from "@cyna/components/business/logo"
import { ReactNode } from "react"

export const LayoutHeader = ({ children }: { children?: ReactNode }) => (
  <header className="sticky top-0 z-20 flex h-16 min-h-16 items-center border-b p-3">
    <Link href={routes.home}>
      <Logo className="text-primary h-6" />
    </Link>
    {children}
  </header>
)
